<template>
  <div class="s-frame">
    <a-layout>
      <a-layout-content>
        <a-row class="s-layout" style="padding-left: 0; padding-right: 0">
          <a-col :span="24" style="display: flex">
            <div class="s-sidebar-div">
              <div class="s-s-font32" style="margin-bottom: 14px">
                키워드 분석
              </div>
              <a-input
                style="margin-bottom: 35px"
                class="s-input1"
                v-model="keyword"
                @pressEnter="onSearch()"
                placeholder="키워드입력"
              >
                <div slot="suffix">
                  <img
                    src="@/assets/images/v4-icon/search.png"
                    style="cursor: pointer"
                    v-on:click="onSearch()"
                  />
                </div>
              </a-input>
              <div
                class="s-s-btn7"
                @click="$router.push('/pages/friday').catch(() => {})"
              >
                <b>키워드 다중 분석하기</b>
              </div>
              <div class="s-s-btn7">이 키워드로 <b>상품 분석</b>보기 ✌️</div>
            </div>
            <div class="s-contents-div" style="overflow-y: scroll">
              <div
                style="margin: 0 auto; text-algin: center; max-width: 1150px"
              >
                <div class="s-s-font31" style="margin-bottom: 15px">
                  키워드 한눈에 보기
                  <a-tooltip>
                    <template slot="title">
                      월간 검색량: 네이버 최근 한달 검색량<br />
                      판매제품수: 네이버쇼핑에 노출되는 상품수<br />
                      콘텐츠량: 네이버에 발행된 해당 키워드의 누적 콘텐츠량
                      (블로그+카페+웹문서)<br />
                      경쟁지수: 검색량 대비 상품수 비율
                    </template>
                    <img
                      style="margin-left: 6px"
                      src="@/assets/images/v4-icon/question.svg"
                    />
                  </a-tooltip>
                </div>
                <a-row>
                  <a-col :span="24">
                    <div style="display: flex">
                      <div style="width: 25%">
                        <div class="s-t-div3" style="margin-right: 20px">
                          <div class="s-t-div3-1">
                            <div>
                              <img src="@/assets/images/v4-icon/ms.svg" />
                            </div>
                            <div class="s-t-col1">
                              <div>월간 검색량</div>
                              <div class="s-t-value1">
                                {{ parseInt(obj.productCnt).toLocaleString()
                                }}<span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="width: 25%">
                        <div class="s-t-div3" style="margin-right: 20px">
                          <div class="s-t-div3-1">
                            <div>
                              <img
                                src="@/assets/images/v4-icon/count-btn.svg"
                              />
                            </div>
                            <div class="s-t-col1">
                              <div>판매제품 수</div>
                              <div class="s-t-value1">
                                {{ parseInt(obj.total).toLocaleString() }}
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="width: 25%">
                        <div class="s-t-div3">
                          <div class="s-t-div3-1">
                            <div>
                              <img src="@/assets/images/v4-icon/book.svg" />
                            </div>
                            <div class="s-t-col1">
                              <div>콘텐츠량</div>
                              <div class="s-t-value1">
                                {{
                                  parseInt(obj.contents.total).toLocaleString()
                                }}<span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="width: 25%">
                        <div class="s-t-div3" style="margin-left: 20px">
                          <div class="s-t-div3-1">
                            <div>
                              <img src="@/assets/images/v4-icon/roket.svg" />
                            </div>
                            <div class="s-t-col1">
                              <div>경쟁지수</div>
                              <div class="s-t-value1">
                                {{ obj.shopPower }}<span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>

                <div
                  class="s-s-font31"
                  style="margin-top: 60px; margin-bottom: 15px"
                >
                  검색량 한눈에 보기
                </div>
                <a-row>
                  <a-col :span="24" style="margin-bottom: 20px">
                    <div style="display: flex">
                      <div style="width: 33%">
                        <div
                          class="s-t-div30"
                          style="margin-right: 20px; display: block"
                        >
                          <div class="s-t-div3-1">
                            <div>
                              <img src="@/assets/images/v4-icon/s.svg" />
                            </div>
                            <div class="s-t-col1">
                              <div>검색량 비교</div>
                            </div>
                          </div>

                          <div
                            class="search"
                            style="margin-top: -40px; text-align: center"
                          >
                            <apexchart
                              width="70%"
                              height="150"
                              type="bar"
                              :options="options1"
                              :series="selectedData"
                            ></apexchart>
                          </div>
                        </div>
                      </div>
                      <div style="width: 33%">
                        <div class="s-t-div30 reverse" style="display: block">
                          <div class="s-t-div3-1">
                            <div>
                              <img src="@/assets/images/v4-icon/device.svg" />
                            </div>
                            <div class="s-t-col1">
                              <div>기기별</div>
                            </div>
                          </div>
                          <div style="margin-top: -50px">
                            <apexchart
                              width="100%"
                              height="70"
                              type="bar"
                              :options="options4"
                              :series="selectedData2"
                            ></apexchart>
                            <div style="display: flex; margin-top: -30px">
                              <div style="left: 0; margin-left: 30px">
                                <span class="s-s-font33">MOBILE <b>0%</b></span>
                              </div>
                              <div
                                style="
                                  right: 0;
                                  position: absolute;
                                  margin-right: 10px;
                                "
                              >
                                <span class="s-s-font33"><b>0%</b> PC</span>
                              </div>
                            </div>
                          </div>
                          <div class="s-t-div3-1" style="margin-top: -20px">
                            <div>
                              <img src="@/assets/images/v4-icon/sex.svg" />
                            </div>
                            <div class="s-t-col1">
                              <div>성별</div>
                            </div>
                          </div>
                          <div style="margin-top: -50px">
                            <apexchart
                              width="100%"
                              height="70"
                              type="bar"
                              :options="options4"
                              :series="selectedData3"
                            ></apexchart>
                            <div style="display: flex; margin-top: -30px">
                              <div style="left: 0; margin-left: 30px">
                                <span class="s-s-font33">MOBILE <b>0%</b></span>
                              </div>
                              <div
                                style="
                                  right: 0;
                                  position: absolute;
                                  margin-right: 10px;
                                "
                              >
                                <span class="s-s-font33"><b>0%</b> PC</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="width: 34%">
                        <div
                          class="s-t-div30"
                          style="margin-left: 20px; display: block"
                        >
                          <div class="s-t-div3-1">
                            <div>
                              <img
                                src="@/assets/images/v4-icon/sales-btn.svg"
                              />
                            </div>
                            <div class="s-t-col1">
                              <div>경쟁지수</div>
                            </div>
                          </div>
                          <div style="margin-top: -40px">
                            <apexchart
                              width="100%"
                              height="150"
                              type="bar"
                              :options="options2"
                              :series="selectedData4"
                            ></apexchart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-col>

                  <a-col :span="12">
                    <a-row class="s-t-div3" style="margin-right: 10px">
                      <a-col :span="9" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/count-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 판매개수</div>
                            <div>
                              {{ parseInt(avgMonthPurchase10).toLocaleString()
                              }}<span>건</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/price-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>평균가격</div>
                            <div>
                              {{ parseInt(avgMonthPrice10).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="8" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/sales-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 매출</div>
                            <div>
                              {{ parseInt(avgMonthSales10).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="12">
                    <a-row class="s-t-div3" style="margin-left: 10px">
                      <a-col :span="9" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/count-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 판매개수</div>
                            <div>
                              {{ parseInt(avgMonthPurchase40).toLocaleString()
                              }}<span>건</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/price-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>평균가격</div>
                            <div>
                              {{ parseInt(avgMonthPrice40).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/sales-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>매출</div>
                            <div>
                              {{ parseInt(avgMonthSales40).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>

                <div
                  class="s-s-font31"
                  style="margin-top: 60px; margin-bottom: 15px"
                >
                  시장규모 한눈에 보기 (1개월 기준)
                  <a-tooltip>
                    <template slot="title">
                      네이버쇼핑 상위제품들의 월평균 예상 시장규모
                    </template>
                    <img
                      style="margin-left: 6px"
                      src="@/assets/images/v4-icon/question.svg"
                    />
                  </a-tooltip>
                </div>
                <a-row>
                  <a-col :span="12">
                    <a-row class="s-t-div3" style="margin-right: 10px">
                      <a-col :span="9" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/count-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 판매개수</div>
                            <div>
                              {{ parseInt(avgMonthPurchase10).toLocaleString()
                              }}<span>건</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/price-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>평균가격</div>
                            <div>
                              {{ parseInt(avgMonthPrice10).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="8" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/sales-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 매출</div>
                            <div>
                              {{ parseInt(avgMonthSales10).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="12">
                    <a-row class="s-t-div3" style="margin-left: 10px">
                      <a-col :span="9" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/count-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 판매개수</div>
                            <div>
                              {{ parseInt(avgMonthPurchase40).toLocaleString()
                              }}<span>건</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/price-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>평균가격</div>
                            <div>
                              {{ parseInt(avgMonthPrice40).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/sales-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>매출</div>
                            <div>
                              {{ parseInt(avgMonthSales40).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>

                <div class="s-s-font3" style="margin-bottom: 15px">
                  시장규모 한눈에 보기 (1개월 기준)
                </div>
                <a-row>
                  <a-col :span="12">
                    <a-row class="s-t-div3" style="margin-right: 10px">
                      <a-col :span="9" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/count-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 판매개수</div>
                            <div>
                              {{ parseInt(avgMonthPurchase10).toLocaleString()
                              }}<span>건</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/price-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>평균가격</div>
                            <div>
                              {{ parseInt(avgMonthPrice10).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="8" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/sales-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 매출</div>
                            <div>
                              {{ parseInt(avgMonthSales10).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="12">
                    <a-row class="s-t-div3" style="margin-left: 10px">
                      <a-col :span="9" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/count-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>월평균 판매개수</div>
                            <div>
                              {{ parseInt(avgMonthPurchase40).toLocaleString()
                              }}<span>건</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/price-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>평균가격</div>
                            <div>
                              {{ parseInt(avgMonthPrice40).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="7" style="display: flex">
                        <div class="s-t-div3-1">
                          <div>
                            <img src="@/assets/images/v4-icon/sales-btn.svg" />
                          </div>
                          <div class="s-s-col1">
                            <div>매출</div>
                            <div>
                              {{ parseInt(avgMonthSales40).toLocaleString()
                              }}<span>원</span>
                            </div>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import {
  getCurrentTimeNew,
  getHex,
  getLicense,
  replaceAll,
  chkLicense,
  showError,
  showSuccess,
  isMobile,
  getCurrentDate4,
  SEARCH_TYPE,
} from "../../components/fnc.js";
import firebase from "firebase";
import ExportExcel from "../../components/ExportExcel/index";
import regression from "regression";

export default {
  components: {
    ExportExcel,
  },
  data() {
    return {
      obj: {
        relKeyword: "",
        shopPower: 0,
        total: 0,
        productCnt: 0,
        contents: {
          blog: 0,
          cafe: 0,
          web: 0,
          total: 0,
        },
      },
      keyword: "",
      selectedData: [],
      selectedData2: [],
      selectedData3: [],
      selectedData4: [],
      options1: {
        legend: {
          show: true,
          showForSingleSeries: false,

          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,

          markers: {
            width: 12,
            height: 12,
            radius: 120,
            fillColors: ["#F2DA00", "#0264FB", "#828282"],
          },
        },
        grid: {
          borderColor: "white",
        },
        chart: {
          type: "bar",
          animations: {
            speed: 200,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          colors: ["#F2DA00", "#0264FB", "#828282"],
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            distributed: true,
            columnWidth: "30%",
          },
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["콘텐츠량", "검색량", "판매제품 수"],
        },

        yaxis: {
          labels: {
            show: false,
          },
        },
      },
      options3: {
        legend: {
          show: true,
          showForSingleSeries: false,

          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,

          markers: {
            width: 12,
            height: 12,
            radius: 120,
            fillColors: [
              "#F2DA00",
              "#0264FB",
              "#828282",
              "#F2DA00",
              "#0264FB",
              "#828282",
            ],
          },
        },
        fill: {
          colors: [
            "#F2DA00",
            "#0264FB",
            "#828282",
            "#F2DA00",
            "#0264FB",
            "#828282",
          ],
        },
        grid: {
          borderColor: "white",
        },
        chart: {
          type: "bar",

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,

            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["10대", "20대", "30대", "40대", "50대", "60대"],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
      options2: {
        legend: {
          show: true,
          showForSingleSeries: false,

          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,

          markers: {
            width: 12,
            height: 12,
            radius: 120,
            fillColors: [
              "#E0E0E0",
              "#F2DA00",
              "#0264FB",
              "#828282",
              "#F2994A",
              "#9B51E0",
            ],
          },
        },
        grid: {
          borderColor: "white",
        },
        chart: {
          type: "bar",
          animations: {
            speed: 200,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          colors: [
            "#E0E0E0",
            "#F2DA00",
            "#0264FB",
            "#828282",
            "#F2994A",
            "#9B51E0",
          ],
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            distributed: true,
          },
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["10대", "20대", "30대", "40대", "50대", "60대"],
        },

        yaxis: {
          labels: {
            show: false,
          },
        },
      },
      options4: {
        legend: {
          show: false,
          showForSingleSeries: false,

          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,

          markers: {
            width: 12,
            height: 12,
            radius: 120,
            fillColors: ["#F2DA00", "#0264FB"],
          },
        },
        grid: {
          borderColor: "#ffffff",
        },
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          animations: {
            speed: 200,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            barHeight: "50%",
          },
        },
        axisTicks: {
          show: false,
          borderType: "solid",
          color: "#ffffff",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        dataLabels: {
          enabled: false,
        },
        axisBorder: {
          show: false,
          color: "#ffffff",
          offsetX: 0,
          offsetY: 0,
        },
        xaxis: {
          lines: {
            show: false,
          },
          categories: ["모바일", "PC"],
          labels: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      },
      avgPurchase40: 0,
      avgSearch: 0,
      avgIndex: 0,
      loading: false,
    };
  },
  computed: {
    getHeight: function () {
      return this.$vssHeight - 450;
    },
  },
  methods: {
    onSearch() {
      this.getRKwdSigle(this.keyword);
    },
    async getRKwdSigle(keyword) {
      var self = this;
      this.onGetDocument(this.keyword);
      var _r = await this.getSnapshot(keyword);

      if (_r == null) {
        await this.$http
          .post("https://asia-east2-storelink-fnc.cloudfunctions.net/getRKwd", {
            keyword,
          })
          .then(function (r) {
            if (r.data.keywordList) {
              r.data.keywordList.forEach((item) => {
                if (item.relKeyword == keyword) {
                  self.onCompensateDataSingle(item, SEARCH_TYPE.DIRTY);
                }
              });
            }
          });
      } else {
        self.onCompensateDataSingle(_r, SEARCH_TYPE.PASS);
      }
    },
    onGetDocument(keyword) {
      var self = this;

      this.$http
        .post(
          "https://asia-east2-storelink-fnc7-8207a.cloudfunctions.net/getCafeCount",
          {
            keyword,
          }
        )
        .then(function (r) {
          self.obj.contents.total += r.data.data.total ? r.data.data.total : 0;
          self.obj.contents.cafe = r.data.data.total ? r.data.data.total : 0;
          console.log(self.obj.contents.cafe);
        });

      this.$http
        .post(
          "https://asia-east2-storelink-fnc7-8207a.cloudfunctions.net/getBlogCount",
          {
            keyword,
          }
        )
        .then(function (r) {
          self.obj.contents.total += r.data.data.total ? r.data.data.total : 0;
          self.obj.contents.blog = r.data.data.total ? r.data.data.total : 0;
          console.log(self.obj.contents.blog);
        });

      this.$http
        .post(
          "https://asia-east2-storelink-fnc7-8207a.cloudfunctions.net/getWebDocumentCount",
          {
            keyword,
          }
        )
        .then(function (r) {
          self.obj.contents.total += r.data.data.total ? r.data.data.total : 0;
          self.obj.contents.web = r.data.data.total ? r.data.data.total : 0;
          console.log(self.obj.contents.web);
        });
    },
    async getProductCountByKeyword(keyword, total) {
      var self = this;

      var _r = await this.getSnapshot(keyword);

      if (_r == null) {
        this.$http
          .post(
            "https://asia-east2-storelink-fnc.cloudfunctions.net/getUrlByJson",
            {
              keyword,
            }
          )
          .then(function (r) {
            var _totalPurchaseCnt = 0;
            var _totalPrice = 0;
            var _idx = 1;
            if (r.data.shoppingResult) {
              var _productTotal = r.data.shoppingResult.total;

              if (r.data.shoppingResult.products) {
                r.data.shoppingResult.products.forEach((item) => {
                  if (item.adId) {
                    //skip
                  } else if (item.purchaseCnt > 0) {
                    _idx++;
                    _totalPurchaseCnt += item.purchaseCnt;
                    _totalPrice += parseInt(item.price);
                  }
                });

                self.tableData = self.tableData.filter((item) => {
                  if (item.relKeyword == keyword) {
                    item["productCnt"] = _productTotal;
                    item["total"] = total;

                    self.obj.relKeyword = keyword;
                    self.obj.shopPower =
                      "1:" + parseFloat(_productTotal / total).toFixed(1);
                    self.obj.total = total;
                    self.obj.productCnt = _productTotal;

var _t = self.selectedData;

_t[1] = total;
_t[2] = _productTotal;

self.selectedData = self.selectedData.filter

                        

                    item["avgPurchase"] = parseInt(_totalPurchaseCnt / _idx);
                    item["avgPrice"] = parseInt(_totalPrice / _idx);
                    item["shopPower"] = parseInt(_productTotal / total);
                    item["shopPower"] = item["shopPower"]
                      ? item["shopPower"]
                      : 0;

                    self.onSaveNaverKeywordSnapshot(keyword, item);
                  }
                  return item;
                });
              }
            }
          });
      } else {
        this.obj.relKeyword = keyword;
        this.obj.shopPower =
          "1:" + parseFloat(_r.productCnt / total).toFixed(1);
        this.obj.total = total;
        this.obj.productCnt = _r.productCnt;
      }
    },
    async getSnapshot(keyword) {
      var db = firebase.firestore();
      var _r = null;
      await db
        .collection("storelink_naver_keyword")
        .where("keyword", "==", keyword)
        .limit(1)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            if (doc.data().obj) {
              _r = doc.data().obj;
            }
          });
        });
      return _r;
    },
    onSaveNaverKeywordSnapshot(_keywordname, _obj) {
      var _key = encodeURIComponent(_keywordname);
      var db = firebase.firestore();
      var self = this;

      var sfDocRef = db.collection("storelink_naver_keyword").doc(_key);

      return db
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then((sfDoc) => {
            if (!sfDoc.exists) {
              db.collection("storelink_naver_keyword")
                .doc(_key)
                .set(
                  {
                    keyword: _keywordname,
                    obj: _obj,
                    eventtime: new Date(),
                    population: 1,
                    mark: "T",
                  },
                  {
                    merge: true,
                  }
                )
                .then(function () {});
            } else {
              var newPopulation = sfDoc.data().population + 1;
              transaction.update(sfDocRef, {
                population: newPopulation,
                eventtime: new Date(),
                obj: _obj,
              });
            }

            // Add one person to the city population.
            // Note: this could be done without a transaction
            //       by updating the population using FieldValue.increment()
          });
        })
        .then(() => {
          console.log("Transaction successfully committed!");
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
        });
    },
    onCompensateDataSingle(data, _mode) {
      var self = this;
      if (data) {
        var _t = data;

        _t["total"] = data.monthlyMobileQcCnt + data.monthlyPcQcCnt;

        if (_mode == SEARCH_TYPE.DIRTY) {
          self.getProductCountByKeyword(_t["relKeyword"], _t["total"]);
        } else {
          console.log(data);
          self.obj.relKeyword = data.relKeyword;
          self.obj.shopPower =
            "1:" + parseFloat(data.productCnt / data.total).toFixed(1);
          self.obj.total = data.total;
          self.obj.productCnt = data.productCnt;
        }
      }
    },
  },
  mounted() {
    this.level = getLicense();

    var _item = {
      name: "test",
      data: [0, 0, 0],
    };
    this.selectedData.push(_item);

    _item = {
      name: "test",
      data: [0, 0, 0, 0, 0, 0],
    };

    this.selectedData4.push(_item);

    this.selectedData2 = [
      {
        name: "PRODUCT A",
        data: [44],
      },
      {
        name: "PRODUCT B",
        data: [56],
      },
    ];

    this.selectedData3 = [
      {
        name: "PRODUCT A",
        data: [13],
      },
      {
        name: "PRODUCT B",
        data: [87],
      },
    ];
  },
};

// 콤마 추가
</script>

<style>
/* 예측치 div */
.s-s-div3 {
  height: 87px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;

  /* 예측치 font */
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-col1 {
  margin-top: -3px;
}

.s-s-col1 div {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;
  padding-left: 10px;
  color: #000000;
}

.s-s-col2 {
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.03em;

  color: #4f4f4f;
  padding-left: 10px;
  padding-top: 0px;
}

.s-f-tbl2:not(.modal) .ant-table-body {
  overflow-x: hidden !important;
}

.s-f-tbl2 th {
  background: white !important;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* border-radius: 0px !important;
  border-top-right-radius: 0px !important; */
  /* identical to box height */
  /* border-bottom: 1px solid black !important; */

  letter-spacing: -0.03em;

  color: #000000;
}

/* .s-f-tbl2 .ant-table-fixed {
  border-top: 1px solid black;
  border-radius: 0px !important;
} */

.s-f-tbl2 .ant-table-header {
  border-top: 1px solid black;
  border-radius: 0px !important;
}

.s-f-tbl2 .ant-table-tbody > tr > td {
  padding: 6px 6px;
  overflow-wrap: break-word;
}

/* .s-f-tbl2 .ant-table-placeholder {
  border-top: 1px solid black !important;
} */

.s-f-tbl2 td.hide,
.s-f-tbl2 th.hide {
  display: none;
}

/* .s-f-tbl2 td:nth-child(3) {
  border-right: 1px solid #e0e0e0;
} */

.s-f-tag {
  width: 62px;
  height: 24px;
  border-radius: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.03em;
  color: #ffffff;
  text-align: center;
  padding-top: 2px;
  margin: 0 auto;
}

.s-r-prod-img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.rank .ant-table-row-cell-break-word {
  font-size: 12px;
}

.scrollable-tbl .ant-table-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scrollable-tbl .ant-table-body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.scrollable-tbl .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

.tbltitle {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;

  color: #000000;
}

/* 예측치 div */
.s-t-div3 {
  height: 98px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
}

.s-t-div30 {
  height: 211px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
}

/* .s-t-div3 > div.c25 {
  width: 25%;
} */

.s-t-div3-1 {
  padding: 30px;
  display: flex;
}

.s-t-col1 div {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -0.03em;
  padding-left: 10px;
  color: #000000;
}

.s-s-font31 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-font32 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-font33 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #828282;
}

.s-t-value1 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}
.s-t-col1 div.s-t-value1 {
  font-weight: bold;
}

.reverse .apexcharts-canvas line {
  display: none;
}

.search .apexcharts-canvas {
  margin: 0 auto;
}

.s-s-btn7 {
  width: 240px;
  height: 60px;
  margin-top: 33px;
  margin-bottom: 33px;
  background: #0264fb;
  border-radius: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  letter-spacing: -0.03em;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
</style>
